<no-data ng-if="vm.centralStationsRdy&&vm.centralStations.length===0" msg="{{'NO_CENTRAL_STATIONS_AVALIABLE'|translate}}"></no-data>
<scrollable-tabset ng-if="!(vm.centralStationsRdy&&vm.centralStations.length===0)">
    <uib-tabset>
        <uib-tab ng-repeat="cs in vm.centralStations" index="$index"
                 select="vm.fetchAccounts(cs)">
            <uib-tab-heading>
                <i class="icon icon-ico-cloud-alarmas-eventos"></i> {{ cs.companyInfo.name |translate}}
            </uib-tab-heading>

            <div class="row centroM">
                <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1 infocm">
                    <img class="imgCM" ng-src="{{cs.cloudImage}}">
                </div>
                <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10 infcm-ubi">
                    <h3> {{cs.companyInfo.name}} - {{cs.companyInfo.shortName}} </h3>
                    <p>{{cs.companyInfo.location.address}}
                    </p>
                    <p><i class="icon icon-ico-cloud-email"></i> <a class="contact">{{cs.companyInfo.email}} <i
                            class="icon icon-ico-cloud-telefono"></i> {{cs.companyInfo.phone}}</a></p>
                </div>

                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">

                    <div class="btn-tag pull-right">
                        <ui-button uib-popover="{{'HELP_REFRESH'| translate}}" popover-trigger="'mouseenter'"
                                   popover-placement="bottom"
                                   action="refresh" mini="true" actionclick="vm.refresh(vm.currentCs)"></ui-button>
                    </div>
                </div>
            </div>

            <br>

            <ui-loading showloading="vm.query" type="loading-partial-view"></ui-loading>
            <no-data ng-if="!vm.query&&vm.accounts.length===0" msg="{{'NO_ACCOUNTS_AVALIABLE'|translate}}"></no-data>

            <scrollable-tabset ng-if="!vm.query">
            <uib-tabset >

                <uib-tab id="1" select="vm.disableMap()">

                    <uib-tab-heading>
                        <i class="icon icon-ico-cloud-cuenta"></i> {{'ASSOCIATED_ACCOUNTS'|translate}}
                    </uib-tab-heading>


                    <div class="list-group" infinite-scroll="vm.fetchAccounts(vm.currentCs)"
                         infinite-scroll-use-document-bottom="true" infinite-scroll-disabled="vm.noMoreDataAvaliable">

                        <a ng-click="vm.openAccountModal(cs,account.idCouch)"
                           ng-repeat="account in vm.accounts" class="list-group-item">

                            <div class="row accountInfo">
                                <div class="row nameAccount">
                                    <div class="col-lg-1 col-md-1 col-xs-1 img-account-type">
                                        <svg class="img-responsive account-type">
                                            <path ng-attr-d="{{account.cloudIcon.image.svgPath}}"
                                                  fill="{{account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                                        </svg>
                                    </div>


                                    <div class="col-lg-10 col-md-10 col-xs-10 inf-name">
                                        <p class="name-account">{{account.name}}</p>
                                        <span class="alarm-ubication">{{account.location.address}} {{account.location.region.formattedAddress}}</span>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-xs-1 inf-name">
                                        <br>

                                        <ui-button action="edit" supermini="true"
                                                   overtext="{{'PROFILE_CHANGE'|translate}}"
                                                   ng-click="vm.openCloudIconModal(cs,account.idCouch);$event.stopPropagation();"></ui-button>
                                    </div>


                                </div>
                                <div class="row dataAccount"
                                     ng-if="vm.account.lastStatus.status == 'OPEN' || vm.account.lastStatus.status == 'CLOSE' ">
                                    <div ng-if="vm.account.lastStatus.status == 'OPEN'">
                                        <div class="col-lg-1 col-md-1 col-xs-1 img-account-type">
                                            <img class="img-responsive armed"
                                                 ng-src="http://nevulametacentro.blob.core.windows.net/metacenter/A_Disarmed.png">
                                        </div>
                                        <div class="col-lg-11 col-md-11 col-xs-11 inf-name">
                                        <span ng-if="!vm.account.lastStatus.accountUser">{{'DISARMED_BY' | translate}}:
                                            {{'UNKNOWN_USER' | translate}} {{account.lastStatus.date}}</span>
                                            <span ng-if="vm.account.lastStatus.accountUser">{{'DISARMED_BY' | translate}}:
                                            {{account.lastStatus.accountUser.name}}
                                            {{account.lastStatus.date}}</span>
                                        </div>

                                    </div>
                                    <div ng-if="vm.account.lastStatus.status == 'CLOSE'">
                                        <div class="col-lg-1 col-md-1 col-xs-1 img-account-type">
                                            <img class="img-responsive account-type"
                                                 ng-src="http://nevulametacentro.blob.core.windows.net/metacenter/A_Armed_Stay.png">
                                        </div>
                                        <div class="col-lg-11 col-md-11 col-xs-1 inf-name">
                                        <span ng-if="!vm.account.lastStatus.accountUser">{{'ARMED_BY' | translate}}:
                                            {{'UNKNOWN_USER' | translate}}{{account.lastStatus.date}}</span>
                                            <span class="armed" ng-if="vm.account.lastStatus.accountUser">{{'ARMED_BY' |
                                            translate}}: {{account.lastStatus.accountUser.name}}
                                            {{account.lastStatus.date}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row dataAccount"
                                     ng-if="vm.account.lastStatus.status != 'OPEN' && vm.account.lastStatus.status != 'CLOSE' ">
                                    <div class="col-lg-1 col-md-1  col-xs-1 img-account-type">
                                    </div>
                                    <div class="col-lg-10 col-md-10 col-xs-10 inf-name">
                                        <span class="alarm-ubication"> {{vm.account.lastStatus.lastUser}}</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                        <ui-loading showloading="vm.updateInProgress&&!vm.query"
                                    type="loading-partial-view"></ui-loading>
                    </div>

                </uib-tab>

                <uib-tab id="2" select="vm.enableMap()">
                    <uib-tab-heading ng-click="changeTab(1)">
                        <i class="icon icon-ico-cloud-mapa"></i> {{'ACCOUNT_MAP'|translate}}s
                    </uib-tab-heading>

                    <div class="row">
                        <div ng-if="vm.showMap" style="height:100%;margin-bottom: 10px">
                            <google-map instance="{{cs.idCentralStation}}" positions="vm.positions"></google-map>
                        </div>
                    </div>

                </uib-tab>


            </uib-tabset>
                </scrollable-tabset>


        </uib-tab>





    </uib-tabset>
</scrollable-tabset>