<no-data ng-if="vm.centralStationsRdy&&vm.centralStations.length===0" msg="{{'NO_CENTRAL_STATIONS_AVALIABLE'|translate}}"></no-data>
<scrollable-tabset ng-if="!(vm.centralStationsRdy&&vm.centralStations.length===0)">
    <uib-tabset>
        <uib-tab ng-repeat="cs in vm.centralStations" index="$index"
                 select="vm.fetchRequests(cs)">
            <uib-tab-heading>
                <i class="icon icon-ico-cloud-alarmas-eventos"></i> {{ cs.companyInfo.name |translate}}

            </uib-tab-heading>


            <div class="row centroM">
                <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1 infocm">
                    <img class="imgCM" ng-src="{{cs.cloudImage}}">
                </div>
                <div class="col-xs-7 col-sm-7 col-md-9 col-lg-9 infcm-ubi">
                    <h3> {{cs.companyInfo.name}} - {{cs.companyInfo.shortName}} </h3>
                    <p>{{cs.companyInfo.location.address}}
                    </p>
                    <p><i class="icon icon-ico-cloud-email"></i> <a class="contact">{{cs.companyInfo.email}} <i
                            class="icon icon-ico-cloud-telefono"></i> {{cs.companyInfo.phone}}</a></p>
                </div>

                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <div class="btn-tag pull-right">
                        <ui-button uib-popover="{{'HELP_REFRESH'| translate}}" popover-trigger="'mouseenter'"
                                   popover-placement="bottom" action="refresh" mini="true"
                                   actionclick="vm.refresh(vm.currentCs)"></ui-button>
                        <ui-button uib-popover="{{'HELP_NEW_REQUEST'| translate}}" popover-trigger="'mouseenter'"
                                   popover-placement="left" action="request-add" mini="true"
                                   actionclick="vm.openNewRequestModal()"></ui-button>
                    </div>
                </div>

            </div>

            <br>
            <no-data ng-if="!vm.query&&vm.requests.length===0" msg="{{'NO_REQUESTS_AVALIABLE'|translate}}"></no-data>
            <ui-loading showloading="vm.query" type="loading-partial-view"></ui-loading>


            <div class="list-group" infinite-scroll="vm.fetchRequests(vm.currentCs)"
                 infinite-scroll-use-document-bottom="true" infinite-scroll-disabled="vm.noMoreDataAvaliable">

                <a ng-click="vm.openRequestModal(request.idCentralStation,request.idCouch)"
                   ng-repeat="request in vm.requests" class="list-group-item">

                    <div class="supportInfo">


                        <div class="row nameAccount" >
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 row-title">
                                <div class="img-account-type">
                                    <svg class="account-type">
                                        <path ng-attr-d="{{request.account.cloudIcon.image.svgPath}}"
                                              fill="{{request.account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                                    </svg>
                                    <!--<img class="img-responsive account-type" ng-src="{{request.iconAccount}}">-->
                                </div>
                                <p>{{request.account.name}}</p>
                            </div>
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div class="pull-right">{{'DATE_CREATE'| translate}}: {{request.createdAt}}</div>
                            </div>
                        </div>


                        <div class="row nameAccount" >
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 row-title">
                                <i class="{{request.iconStatus}}"></i>
                                <span>{{request.code}} / {{request.incidence}}</span>
                            </div>
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div class="pull-right">{{request.origin}}</div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <ui-button class="pull-right" action="delete" supermini="true" overtext="{{'DELETE_REQUEST'|translate}}"
                                           ng-click="vm.delete(request);$event.stopPropagation();" disabled="request.status != 'SENT'"></ui-button>
                            </div>
                        </div>


                    </div>

                </a>
                <ui-loading showloading="vm.updateInProgress&&!vm.query" type="loading-partial-view"></ui-loading>
            </div>


        </uib-tab>


    </uib-tabset>
</scrollable-tabset>