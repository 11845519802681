<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true"
                   class="pull-right"></ui-button>

        <div class="row">
            <div class="col-xs-10">
                <h3>{{vm.title | translate}}</small></h3>
            </div>
        </div>
    </div>
    <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>

    <div class="modal-body" ng-show="!vm.init">

        <form role="form" name="vm.requestForm" novalidate>


            <div class="row element">
                <div class="col-lg-1 alarms-name">
                    <svg class="icon svg-account-type">
                        <path ng-attr-d="{{vm.account.cloudIcon.image.svgPath}}"
                              fill="{{vm.account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                    </svg>
                </div>
                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div class="form-group"
                         ng-class="{'has-error' : !!vm.requestValidation&&vm.requestForm.account.$error.required}">
                        <label for="account">{{'ACCOUNT'|translate}}</label>
                        <ui-button class="help" action="help"
                                   overtext="{{'HELP_ACCOUNT_ORIGIN'|translate}}"></ui-button>
                        <ui-select ng-model="vm.account" on-select="change()" name="account" id="account"
                                   ng-disabled="vm.edit" ng-required="true">

                            <ui-select-match placeholder="{{'ACCOUNT' | translate}}">{{$select.selected.name}}
                            </ui-select-match>

                            <ui-select-choices
                                    repeat="account in vm.accounts | filter: {name: $select.search} track by account.idCouch">
                                <div ng-bind-html="account.name | highlight: $select.search"></div>
                            </ui-select-choices>

                        </ui-select>

                        <p class="help-block" ng-if="(!!vm.requestValidation&&vm.requestForm.account.$error.required)">
                            {{'FIELD_REQUIRED' | translate}}</p>
                    </div>
                </div>
            </div>


            <div class="row element" ng-if="vm.account">
                <div class="col-lg-1 alarms-name">
                    <img class="account-type" ng-src="{{vm.account.centralStation.cloudImage}}">
                </div>
                <div class="col-xs-10 col-lg-offset-1 col-sm-10 col-md-10 col-lg-10">
                    <div class="form-group">
                        <label for="centralStation">{{'CENTRAL_STATION'|translate}}</label>
                        <ui-button class="help" action="help"
                                   overtext="{{'HELP_STATION_CENTRAL_ORIGIN'|translate}}"></ui-button>
                        <input type="text" class="form-control" name="centralStation" id="centralStation"
                               ng-model="vm.account.centralStation.companyInfo.name"
                               placeholder="CENTRAL_STATION" ng-disabled="true"/>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>


            <div class="row element" ng-if="vm.new || (vm.edit && vm.request.incidence)">
                <div class="col-lg-1 alarms-name">
                    <i class="icon icon-ico-cloud-solicitud"></i>
                </div>
                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div class="form-group"
                         ng-class="{'has-error':vm.requestValidation&&vm.requestForm.incidence.$error.required}">
                        <label for="incidence">{{'INCIDENCE'|translate}}</label>
                        <ui-button class="help" action="help"
                                   overtext="{{'HELP_vm.request_INCIDENCE'|translate}}"></ui-button>
                        <input ng-disabled="vm.edit" id="incidence" type="text" class="form-control" name="incidence"
                               ng-model="vm.request.incidence"
                               placeholder="{{'INCIDENCE'|translate}}" ng-readonly="update && !edit" required/>
                        <p class="help-block"
                           ng-if="vm.requestValidation&&vm.requestForm.incidence.$error.required">
                            {{'FIELD_REQUIRED' |
                            translate}}</p>

                    </div>
                </div>
            </div>


            <div class="row element" ng-if="vm.new || (vm.edit && vm.request.observation)">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div class="form-group">
                        <label for="observation">{{'OBSERVATION'|translate}}</label>
                        <ui-button class="help" action="help"
                                   overtext="{{'HELP_vm.request_OBSERVATION'|translate}}"></ui-button>
                        <input id="observation" type="text" class="form-control" name="observation"
                               ng-disabled="vm.edit"
                               ng-model="vm.request.observation"
                               placeholder="{{'OBSERVATION'|translate}}"
                               required/>
                    </div>
                </div>
            </div>

            <div class="row" ng-if="vm.new || (vm.edit && !!vm.request.image)">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>


            <div ng-if="vm.new || (vm.edit && !!vm.request.image)" class="row">
                <div class="col-lg-1 alarms-name"></div>
                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div class="form-group col-lg-4">
                        <div class="row element">
                            <label>{{'IMAGE'|translate}}</label>
                            <ui-button class="help" action="help"
                                       overtext="{{'HELP_IMAGE_vm.request'|translate}}"></ui-button>
                        </div>
                        <div ng-if="vm.new" class="row" ng-hide="update && !edit">
                            <a uib-popover="{{'HELP_ADD_IMAGE'| translate}}" popover-trigger="'mouseenter'"
                               popover-placement="bottom"
                               class="btn btn-default" accept="image/*" ngf-select=""
                               ng-model="vm.request.image"
                               name="image"><i class="fa fa-camera" aria-hidden="true"></i></a>
                            &nbsp;
                            <ui-button uib-popover="{{'HELP_DELETE_IMAGE'| translate}}"
                                       popover-trigger="'mouseenter'"
                                       popover-placement="bottom" action="delete" mini="true"
                                       actionclick="actions.DeleteImage()"></ui-button>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <img ng-if="vm.request.image.name" class="zoom-wt" width="100" height="80"
                             ngf-src="vm.request.image">
                        <img ng-if="vm.request.cloudImage" class="zoom-wt" width="100" height="80"
                             ngf-src="vm.request.cloudImage">
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>

            <div ng-if="vm.new || (vm.edit && !!vm.request.preferredSchedule)" class="row element">
                <div class="col-lg-1 alarms-name"></div>

                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <label>{{'SCHEDULE_AVAILABLE'|translate}}</label>
                    <ui-button class="help" action="help"
                               overtext="{{'HELP_SCHEDULE_AVAILABLE'|translate}}"></ui-button>
                    <div class="row">

                        <div ng-repeat="(day,preferredSchedule) in vm.preferredSchedule"
                             class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <label class="checkbox-inline">
                                <input type="checkbox"
                                       ng-change="vm.updateShedule(day)"
                                       ng-model="vm.days[day]"
                                       ng-disabled="vm.edit"> {{day.toUpperCase() | translate}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row" ng-if="(vm.edit && !vm.request.preferredSchedule)">
                <div class="col-lg-11 col-xs-11 col-md-11 col-lg-offset-1 col-md-offset-1 col-xs-offset-1">
                    {{'NO_PREFERRED_SCHEDULE' | translate}}
                </div>
            </div>

            <br/>


            <div class="row element" ng-if="vm.selectedDays">
                <div class="col-lg-1 alarms-name"></div>
                <div class="col-xs-11 col-sm-11 col-md-4 col-lg-4">
                    <label>{{'FROM'|translate}}</label>
                    <ui-button class="help" action="help"
                               overtext="{{'HELP_REQUEST_FROM'|translate}}"></ui-button>
                    <div uib-timepicker ng-model="vm.timeInitial" hour-step="vm.dateParams.hourStep"
                         min="vm.dateParams.min" max="vm.dateParams.max"
                         minute-step="vm.dateParams.minStep"
                         show-meridian="meridian"
                         ng-disabled="vm.edit"
                         readonly-input="vm.edit" mousewheel="!vm.edit"
                         arrowkeys="!vm.edit"
                         show-spinners="!vm.edit"></div>
                </div>
                <div class="col-xs-11 col-sm-11 col-md-3 col-lg-3"></div>
                <div class="col-xs-11 col-sm-11 col-md-4 col-lg-4">
                    <label>{{'TO'|translate}}</label>
                    <ui-button class="help" action="help"
                               overtext="{{'HELP_REQUEST_TO'|translate}}"></ui-button>
                    <div uib-timepicker ng-model="vm.timeFinal" min="vm.dateParams.min" max="vm.dateParams.max"
                         hour-step="vm.dateParams.hourStep" minute-step="vm.dateParams.minStep"
                         readonly-input="vm.edit" mousewheel="!vm.edit"
                         show-meridian="meridian"
                         readonly-input="vm.edit" mousewheel="!vm.edit"
                         ng-disabled="vm.edit"
                         arrowkeys="!vm.edit"
                         show-spinners="!vm.edit"></div>
                </div>
            </div>


        </form>
        <div class="modal-footer">
            <ui-button action="save" text="{{'CLOSE'|translate}}" actionclick="vm.cancel()"
                       ng-if="vm.edit"></ui-button>
            <ui-button status="{{vm.statusView}}" action="cancel" actionclick="vm.cancel()" ng-if="vm.new"></ui-button>
            <ui-button status="{{vm.statusView}}" action="save" actionclick="vm.save()" ng-if="vm.new"></ui-button>
        </div>

    </div>
</div>