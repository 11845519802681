<div class="modal-cloud">
    <form name="vm.paymentForm" novalidate>
        <div class="modal-header">
            <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
            <div class="row">
                <div class="col-xs-10">
                    <h3>{{!!vm.makePayment ? 'PAYMENT':'NEW_PAYMENT_METHODS'|translate}}</h3>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <br>
            <div class="form" >

                <h4 ng-if="vm.makePayment" class="name-account text-center"><strong>{{'PAYMENT_AMOUNT' | translate}}:
                    {{vm.paymentData.total | currency}}</strong></h4>

                <div class="panel panel-default" ng-if="vm.makePayment">
                    <div class="panel-heading">
                        {{('PAYMENT_FORM'|translate).toUpperCase()}}
                    </div>

                    <div class="panel-body">
                        <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>
                        <div ng-if="!vm.init " class="col-lg-12 col-xs-12 col-sm-12 col-md-12">
                            <ui-select ng-model="vm.method" name="paymentMethod" on-select="vm.unregistered()"
                                       id="paymentMethod" required>
                                <ui-select-match placeholder="{{'BILLINGS_CARD_TYPE'|translate}}">
                                    {{$select.selected.maskedNumber}}
                                </ui-select-match>
                                <ui-select-choices
                                        repeat="method in vm.methods | filter: {maskedNumber: $select.search} track by method._id">
                                    <span ng-bind-html="method.paymentMethod  | highlight: $select.search"></span> -
                                    <span ng-bind-html="method.maskedNumber | highlight: $select.search"></span> -
                                    <span class="text-uppercase"
                                          ng-bind-html="method.name | highlight: $select.search"></span>
                                </ui-select-choices>
                            </ui-select>
                            <br>
                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" ng-if="vm.makePayment && !(vm.unregisteredPayment)&&!!vm.method">
                            <br>
                            <label>{{'CARD_DATA'|translate}}</label>
                            <div class="form-group" show-errors>
                                <input type="text" class="form-control" name="securityCode" id="securityCodet"
                                       ng-model="vm.paymentMethod.securityCode"
                                       placeholder="{{'VERIFICATION_CODE'|translate}}" ng-disabled="false" required/>
                            </div>
                            <p class="help-block"
                               ng-if="paymentForm.$submitted&&paymentForm.securityCode.$error.required">
                                {{'FIELD_REQUIRED'|translate}}</p>
                        </div>

                    </div><!-- PAGAR CON TARJETA YA REGISTRADA--><!-- PAGAR CON TARJETA YA REGISTRADA-->
                </div>


                <!-- AGREGAR UNA TARJETA O PAGAR CON TARJETA NO REGISTRADA-->
                <div ng-if="!vm.makePayment || vm.unregisteredPayment" class="panel panel-default">
                    <div class="panel-heading">

                        <div class="row">

                            <div class="col-lg-3 col-md-3 col-xs-3">
                                {{('PAYMENT_METHOD'|translate).toUpperCase()}}
                            </div>

                            <div ng-if="vm.makePayment" class="col-lg-8 col-md-8 col-xs-8">
                                <span class="pull-right">{{'SAVE_PAYMENT_METHOD'|translate}}</span>
                            </div>
                            <div ng-if="vm.makePayment" class="col-lg-1 col-md-1 col-xs-1">
                                <small class="pull-left">
                                    <switch ng-model="vm.saveMethod"
                                            class="green">
                                    </switch>
                                </small>
                            </div>


                        </div>
                    </div>


                    <div class="panel-body">


                        <fieldset class="frame col-md-12">
                            <legend class="title-bordered">{{'CARD_DATA'|translate}}</legend>

                            <div class="panel panel-default">
                                <div class="panel-body">

                                    <div class="row">
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group"
                                                 ng-class="{'has-error': vm.paymentForm.$submitted&&!vm.paymentMethod.cardType }">
                                                <ui-select
                                                        ng-model="vm.paymentMethod.cardType"
                                                        name="cardType"
                                                        id="cardType" required>
                                                    <ui-select-match
                                                            placeholder="{{'BILLINGS_CARD_TYPE'|translate}}">
                                                        {{$select.selected.name}}
                                                    </ui-select-match>
                                                    <ui-select-choices
                                                            repeat="cardType in vm.cardTypes | filter: {name: $select.search} track by cardType.id">
                                                        <div ng-bind-html="cardType.name | highlight: $select.search"></div>
                                                    </ui-select-choices>
                                                </ui-select>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&!vm.paymentMethod.cardType">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>

                                        </div>

                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <div class="form-group" show-errors>
                                                <input type="number" maxlength="16" class="form-control"
                                                       name="creditcardNumber"
                                                       id="creditcardNumber"
                                                       ng-model="vm.paymentMethod.creditcardNumber"
                                                       placeholder="{{'CREDIT_CARD_NUMBER'|translate}}"
                                                       pattern="[0-9]{13,20}"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.creditcardNumber.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <div class="form-group" show-errors>
                                                <input type="text" class="form-control" name="namePrinted"
                                                       id="namePrinted"
                                                       ng-model="vm.paymentMethod.namePrinted"
                                                       placeholder="{{'NAME_PRINTED'|translate}}"
                                                       ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.namePrinted.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-6">
                                            <div class="form-group" show-errors>
                                                <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                                    <div class="form-group"
                                                         ng-class="{'has-error': vm.paymentForm.$submitted&&!vm.paymentMethod.expiration.month }">
                                                        <ui-select on-select="selectMonth();"
                                                                   ng-model="vm.paymentMethod.expiration.month"
                                                                   ng-required="true">
                                                            <ui-select-match placeholder="{{'MONTH'|translate}}"
                                                                             search-enabled="false">
                                                                <span ng-bind="$select.selected.name | translate"></span>
                                                            </ui-select-match>
                                                            <ui-select-choices
                                                                    repeat="month in (vm.months | filter: $select.search) track by month.id">
                                                                <span ng-bind="month.name | translate"></span>
                                                            </ui-select-choices>
                                                        </ui-select>
                                                        <p class="help-block"
                                                           ng-if="vm.paymentForm.$submitted&&!vm.paymentMethod.expiration.month">
                                                            {{'FIELD_REQUIRED'|translate}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-xs-5">
                                                    <div class="form-group" show-errors>
                                                        <input type="number" min="{{vm.currYear}}" maxlength="4"
                                                               class="form-control"
                                                               name="expiration" id="expirationYear"
                                                               ng-model="vm.paymentMethod.expiration.year"
                                                               placeholder="{{'FORMAT_DATE_YEAR'|translate}}"
                                                               ng-disabled="false"
                                                               required/>
                                                        <p class="help-block"
                                                           ng-if="vm.paymentForm.$submitted&&vm.paymentForm.expiration.$error.required">
                                                            {{'FIELD_REQUIRED'|translate}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="numberSecurity"
                                                       id="numberSecurity" type="text"
                                                       ng-model="vm.paymentMethod.securityCode"
                                                       placeholder="{{'VERIFICATION_CODE'|translate}}"
                                                       ng-disabled="false" required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.numberSecurity.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>

                        <br>


                        <fieldset class="frame col-md-12">
                            <legend class="title-bordered">{{'CARDMEMBER_DATA'|translate}}</legend>

                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <div class="form-group" show-errors>
                                                <input type="text" class="form-control" name="nameCardmember"
                                                       id="nameCardmember"
                                                       ng-model="vm.paymentMethod.nameCardmember"
                                                       placeholder="{{'COMPLETE_NAME'|translate}}"
                                                       ng-disabled="false" required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.nameCardmember.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group" show-errors>
                                                <input type="text" class="form-control"
                                                       name="identificationCardmember"
                                                       id="identificationCardmember"
                                                       ng-model="vm.paymentMethod.identificationCardmember"
                                                       placeholder="{{'IDENTIFICATION'|translate}}"
                                                       ng-disabled="false" required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.identificationCardmember.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="emailCardmember"
                                                       id="emailCardmember" type="email"
                                                       ng-model="vm.paymentMethod.emailCardmember"
                                                       placeholder="{{'EMAIL'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.emailCardmember.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="phoneCardmember"
                                                       id="phoneCardmember" type="text"
                                                       ng-model="vm.paymentMethod.phoneCardmember"
                                                       placeholder="{{'PHONE'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.phoneCardmember.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </fieldset>


                        <fieldset class="frame col-md-12">
                            <legend class="title-bordered">{{'BIRTH_DATE'|translate}}</legend>

                            <div class="panel panel-default">
                                <div class="panel-body">

                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div class="form-group" show-errors>
                                            <input type="text" class="form-control"
                                                   ng-model="vm.paymentMethod.birthDate.day"
                                                   name="brithDateday"
                                                   ng-required="true" placeholder="{{'DAY' | translate}}" required>
                                            <p class="help-block"
                                               ng-if="vm.paymentForm.$submitted&&vm.paymentForm.brithDateday.$error.required">
                                                {{'FIELD_REQUIRED'|translate}}</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group"
                                             ng-class="{'has-error': vm.paymentForm.$submitted&&!vm.paymentMethod.birthDate.month }">
                                            <ui-select ng-model="vm.paymentMethod.birthDate.month"
                                                       ng-required="true">
                                                <ui-select-match placeholder="{{'MONTH'|translate}}"
                                                                 search-enabled="false">
                                                    <span ng-bind="$select.selected.name | translate"></span>
                                                </ui-select-match>
                                                <ui-select-choices
                                                        repeat="month in (vm.months | filter: $select.search) track by month.id">
                                                    <span ng-bind="month.name | translate"></span>
                                                </ui-select-choices>
                                            </ui-select>
                                            <p class="help-block"
                                               ng-if="vm.paymentForm.$submitted&&!vm.paymentMethod.birthDate.month">
                                                {{'FIELD_REQUIRED'|translate}}</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div class="form-group" show-errors>

                                            <input type="number" min="{{vm.currYear-118}}" max="{{vm.currYear-18}}"
                                                   maxlength="4"
                                                   class="form-control" ng-model="vm.paymentMethod.birthDate.year"
                                                   name="vm.brithDateYear"
                                                   ng-required="true"
                                                   placeholder="{{'FORMAT_DATE_YEAR' | translate}}"
                                                   required>

                                            <p class="help-block"
                                               ng-if="vm.paymentForm.$submitted&&vm.paymentForm.brithDateYear.$error.required">
                                                {{'FIELD_REQUIRED'|translate}}</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </fieldset>


                        <fieldset class="frame col-md-12">
                            <legend class="title-bordered">{{'ADDRESS_INVOICING'|translate}}</legend>

                            <div class="panel panel-default">
                                <div class="panel-body">

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="avenuebilling" id="avenuebilling"
                                                       type="text"
                                                       ng-model="vm.paymentMethod.avenuebilling"
                                                       placeholder="{{'AVENUE'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.avenuebilling.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group" show-errors>
                                                <input type="text" class="form-control" name="citybilling"
                                                       id="citybilling" type="text"
                                                       ng-model="vm.paymentMethod.citybilling"
                                                       placeholder="{{'CITY'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.citybilling.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="statebilling" id="statebilling"
                                                       type="text"
                                                       ng-model="vm.paymentMethod.statebilling"
                                                       placeholder="{{'STATE'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.statebilling.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group"
                                                 ng-class="{'has-error': vm.paymentForm.$submitted&&!vm.paymentMethod.countrybilling}">
                                                <ui-select
                                                           ng-model="vm.paymentMethod.countrybilling"
                                                           name="countrybilling" id="countrybilling" required>
                                                    <ui-select-match placeholder="{{'COUNTRY'|translate}}">
                                                        {{$select.selected.name}}
                                                    </ui-select-match>
                                                    <ui-select-choices
                                                            repeat="country in vm.countrys | filter: {name: $select.search} track by country.id">
                                                        <div ng-bind-html="country.name | highlight: $select.search"></div>
                                                    </ui-select-choices>
                                                </ui-select>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&!vm.paymentMethod.countrybilling">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="zipcodebilling"
                                                       id="zipcodebilling"
                                                       type="text"
                                                       ng-model="vm.paymentMethod.zipcodebilling"
                                                       placeholder="{{'ZIPCODE'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.zipcodebilling.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                            <div class="form-group" show-errors>
                                                <input class="form-control" name="phonebilling" id="phonebilling"
                                                       type="text"
                                                       ng-model="vm.paymentMethod.phonebilling"
                                                       placeholder="{{'PHONE'|translate}}" ng-disabled="false"
                                                       required/>
                                                <p class="help-block"
                                                   ng-if="vm.paymentForm.$submitted&&vm.paymentForm.phonebilling.$error.required">
                                                    {{'FIELD_REQUIRED'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>


                    </div>

                </div>


            </div>



        </div>


        <div class="modal-footer">
            <ui-button ng-if="!vm.makePayment" action="save" text="{{'REGISTER'|translate}}"
                       actionclick="vm.engageMechanism()"></ui-button>
            <ui-button ng-if="vm.makePayment" action="save" text="{{'BILLINGS_PAY'|translate}}"
                       actionclick="vm.engageMechanism()"></ui-button>
            <ui-button action="cancel" text="{{'CANCEL'|translate}}" actionclick="vm.cancel()"></ui-button>
        </div>
    </form>
</div>
