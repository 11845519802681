<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar" ng-controller="SidebarCtrl">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <ul  data-widget="tree" class="sidebar-menu">
      <li class="treeview" ng-repeat="menuItem in menu track by $index">
        <a ng-if="menuItem.link"  class="som" href="{{menuItem.link}}">
          <i class="{{menuItem.icon}}"></i>
          <span>{{menuItem.nombre | translate}}</span>
          <i ng-if="menuItem.subniveles"  class="fa fa-angle-left pull-right"></i>
        </a>
        <a ng-if="!menuItem.link">
          <i class="{{menuItem.icon}}"></i>

          <span>{{menuItem.nombre | translate}}</span>
          <i ng-if="menuItem.subniveles"  class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu" ng-if="menuItem.subniveles.length > 0">
          <li ng-repeat="subnivel in menuItem.subniveles track by $index" >
            <a href="{{subnivel.link}}">
              <i class="{{subnivel.icon}}"></i>
              {{subnivel.nombre | translate}}
              <i ng-if="subnivel.subniveles" class="fa fa-angle-left pull-right"></i>
            </a>
            <ul ng-if="subnivel.subniveles" class="treeview-menu" >
              <li ng-repeat="subnivel_2 in subnivel.subniveles track by $index" >
                <a href="{{subnivel_2.link}}"><i class="{{subnivel_2.icon}}"></i>{{subnivel_2.nombre}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>
