    <form name="vm.form" novalidate xmlns="http://www.w3.org/1999/html">
    <div class="modal-cloud">
        <div class="modal-header">
            <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
            <h3 class="modal-title">{{'NEW_NOTE' | translate }}</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <fieldset>
                        <legend>{{'FORM_NEW_NOTE'|translate}}</legend>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group" show-errors>
                                    <label for="comment">{{'DESCRIPTION' | translate}} *</label>
                                    <ui-button action="help" overtext={{'HELP_SUPPORT_TICKETS_NOTE'|translate}}></ui-button>
                                    <textarea id="comment" type="text" class="form-control" ng-model="vm.event.comment" name="comment" tabindex="2"
                                              placeholder="{{'HELP_SUPPORT_TICKETS_NOTE' | translate}}" required></textarea>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <ui-button status="bottomStatus" action="cancel" actionclick="vm.cancel()"></ui-button>
            <ui-button status="bottomStatus" action="add" actionclick="vm.save()" disabled="vm.form.$invalid"></ui-button>
        </div>
    </div>
</form>
