<no-data ng-if="vm.centralStationsRdy&&vm.centralStations.length===0" msg="{{'NO_CENTRAL_STATIONS_AVALIABLE'|translate}}"></no-data>
<scrollable-tabset ng-if="!(vm.centralStationsRdy&&vm.centralStations.length===0)">

    <uib-tabset>
        <uib-tab ng-repeat="cs in vm.centralStations" index="$index"
                 select="vm.fetchAccounts(cs)">
            <uib-tab-heading>
                <i class="icon icon-ico-cloud-alarmas-eventos"></i> {{ cs.companyInfo.name |translate}}
            </uib-tab-heading>


            <div class="row centroM">
                <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1 infocm">
                    <img class="imgCM" ng-src="{{cs.cloudImage}}">
                </div>
                <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10 infcm-ubi">
                    <h3> {{cs.companyInfo.name}} - {{cs.companyInfo.shortName}} </h3>
                    <p>{{cs.companyInfo.location.address}}
                    </p>
                    <p><i class="icon icon-ico-cloud-email"></i> <a class="contact">{{cs.companyInfo.email}} <i
                            class="icon icon-ico-cloud-telefono"></i> {{cs.companyInfo.phone}}</a></p>
                </div>

                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <div class="btn-tag pull-right">
                        <ui-button uib-popover="{{'HELP_REFRESH'| translate}}" popover-trigger="'mouseenter'"
                                   popover-placement="bottom"
                                   action="refresh" mini="true" actionclick="vm.refresh(vm.currentCs)"></ui-button>
                    </div>
                </div>


            </div>

            <br>
            <no-data ng-if="!vm.queryAccounts&&vm.accounts.length===0" msg="{{'NO_ACCOUNTS_AVALIABLE'|translate}}"></no-data>
            <scrollable-tabset ng-if="!(vm.queryAccounts)">
                <uib-tabset>

                    <uib-tab index="$index" select="vm.fetchBills(account)" ng-repeat="account in vm.accounts">
                        <uib-tab-heading>
                            <svg class="icon">
                                <path ng-attr-d="{{account.cloudIcon.image.svgPath}}"
                                      fill="{{account.cloudIcon.color.value}}"
                                      transform="scale(0.1)"/>
                            </svg>
                            {{ account.name |translate}}
                        </uib-tab-heading>

                        <div class="panel panel-default">
                            <div class="panel-body accountInfo">
                                <div class="row nameAccount">
                                    <div class="col-lg-1 col-md-1 col-xs-1 img-account-type" style="cursor: text">
                                        <svg class="pull-right icon img-responsive account-type">
                                            <path ng-attr-d="{{account.cloudIcon.image.svgPath}}"
                                                  fill="{{account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                                        </svg>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-xs-8 inf-name">
                                        <p class="name-account"><strong>{{account.name}}</strong></p>
                                        <div class="row"></div>
                                        <a class="alarm-ubication">{{account.location.region.formattedAddress}}</a>
                                    </div>

                                    <div class="col-lg-3 col-md-3  col-xs-3">

                                    </div>
                                </div>


                            </div>
                        </div>


                        <br>

                        <no-data ng-if="!vm.query&&vm.Bills.length===0" msg="{{'NO_PAYMENTS_AVALIABLE'|translate}}"></no-data>
                        <div class="list-group accountInfo" infinite-scroll="vm.fetchBills(vm.currentAcc)"
                             infinite-scroll-use-document-bottom="true"
                             infinite-scroll-disabled="vm.noMoreDataAvaliable">

                            <a ng-click="vm.openBillsModal(cs,bill)"
                               ng-repeat="bill in vm.Bills" class="list-group-item">
                                <div class="row nameAccount">

                                    <div class="col-lg-1 col-md-1 col-xs-1 row-title">
                                        <br/>
                                        <i class="icon icon-ico-cloud-facturacion-pagos pull-right">

                                        </i>
                                    </div>
                                    <div class="col-lg-11 col-md-11 col-xs-11 receipt">
                                        <p>
                                            {{'BILL'|translate}}: {{bill.proforma.number | translate}} ({{bill.status |
                                            translate}})
                                            <span class="pull-right total-receipts"> {{bill.proforma.total | currency}}</span>
                                        </p>

                                        <span class="">{{'EMITTER'|translate}}: {{cs.companyInfo.shortName | translate}} - {{'RECEIVER'|translate}}:
                                    {{bill.proforma.client.data.name | translate}}</span>
                                        <br><br>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <ui-loading showloading="vm.query||vm.updateInProgress"
                                    type="loading-partial-view"></ui-loading>
                    </uib-tab>

                </uib-tabset>
            </scrollable-tabset>
            <ui-loading showloading="vm.queryAccounts" type="loading-partial-view"></ui-loading>

        </uib-tab>


    </uib-tabset>
</scrollable-tabset>


