<span><button ng-if="action!='help'" class="{{cssButton}} {{superminiCss}} {{cssAnimate}}" style="{{style}}" ng-disabled="isDisabled" ng-click="actionClickProcess()" uib-popover="{{overtext}}"  popover-trigger="'mouseenter'">
  <i ng-if="!loading" class="{{iconButton}}"></i>
  <i ng-if="loading" class="fa fa-spinner fa-pulse active"></i>
  <span ng-if="!mini && !supermini && !loading">{{defaultText}}</span>
  <span ng-if="!mini && !supermini && loading">{{"LOADING" | translate}}</span>
  <!--<span class="progress">-->
  <!--<span class="progress-inner"></span>-->
  <!--</span>-->
</button>
<a ng-if="action=='help'" class="{{cssButton}} {{superminiCss}}" uib-popover="{{overtext}}"  popover-trigger="'mouseenter'">
  <i ng-if="!loading" class="{{iconButton}}"></i>
  <span ng-if="!mini && !supermini && !loading">{{defaultText}}</span>
</a>
</span>
