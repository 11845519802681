<no-data ng-if="vm.centralStationsRdy&&vm.centralStations.length===0" msg="{{'NO_CENTRAL_STATIONS_AVALIABLE'|translate}}"></no-data>
<scrollable-tabset ng-if="!(vm.centralStationsRdy&&vm.centralStations.length===0)">
    <uib-tabset>
        <uib-tab ng-repeat="cs in vm.centralStations" index="$index"
                 select="vm.fetchAlarms(cs.idCentralStation)">
            <uib-tab-heading ng-click="changeTab(1)">
                <i class="icon icon-ico-cloud-alarmas-eventos"></i> {{ cs.companyInfo.name |translate}}

            </uib-tab-heading>


            <div class="row centroM">
                <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1 infocm">
                    <img class="imgCM" ng-src="{{cs.cloudImage}}">
                </div>
                <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10 infcm-ubi">
                    <h3> {{cs.companyInfo.name}} - {{cs.companyInfo.shortName}} </h3>
                    <p>{{cs.companyInfo.location.address}}
                    </p>
                    <p><i class="icon icon-ico-cloud-email"></i> <a class="contact">{{cs.companyInfo.email}} <i
                            class="icon icon-ico-cloud-telefono"></i> {{cs.companyInfo.phone}}</a></p>
                </div>

                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <button type="button" class="btn btn-add pull-right" ng-click="vm.refresh(vm.currentCs)"
                            uib-popover="{{'HELP_REFRESH'|translate}}" popover-trigger="'mouseenter'"
                            popover-placement="left">
                        <span class="icon icon-ico-cloud-actualizar"></span>
                    </button>
                </div>


            </div>

            <ui-loading showloading="vm.query" type="loading-partial-view"></ui-loading>

            <no-data ng-if="!vm.query&&vm.alarms.length===0" msg="{{'NO_ALARMS_AVALIABLE'|translate}}"></no-data>


            <div nf-if="!(!vm.query&&vm.alarms.length===0)" class="list-group" infinite-scroll="vm.fetchAlarms(vm.currentCs)"
                 infinite-scroll-use-document-bottom="true" infinite-scroll-disabled="vm.noMoreDataAvaliable">
                <a href="#" ng-click="vm.openAlarmsModal(alarm.idCentralStation,alarm.idCouch)"
                   ng-repeat="alarm in vm.alarms" class="list-group-item">
                    <div class="row central-data">
                        <div class="form-group col-lg-12 col-md-12 col-xs-12">
                            <h2>
                                <i class="iconos {{alarm.icon}}" ng-style="{'color':alarm.color}"></i>
                                {{alarm.stateRule.classification.key? alarm.stateRule.classification.key: "UNKNOWN" |
                                translate}} {{ alarm.stateRule.classification.key? '-': ' '}}
                                {{alarm.stateRule.classification.subClassification.key | translate}}
                                - {{alarm.idCouch.split(':')[1]}}
                                <p class="pull-right"> {{alarm.createdAtLocal}}</p>

                            </h2>

                            <div class="row alarms">
                                <div class=" col-lg-1 col-md-1 col-xs-1 img-account-type">
                                    <svg class="account-type">
                                        <path ng-attr-d="{{alarm.account.data.cloudIcon.image.svgPath}}"
                                              fill="{{alarm.account.data.cloudIcon.color.value}}"
                                              transform="scale(0.1)">
                                        </path>

                                    </svg>
                                </div>

                                <div class="col-lg-11 col-md-11 col-xs-11 inf-ubication">
                                    <p class="alarm-ubication text-capitalize">{{alarm.account.data.name}}
                                        <span ng-if="alarm.stateRule.classification.key ==  'MOBILE_PANIC'">
                                <i class="iconos icon icon-ico-cloud-apps-panico"></i>
                                    {{'BY'|translate}}:  {{alarm.clientName}}
                                </span>

                                        <span ng-if="alarm.stateRule.classification.key !=  'MOBILE_PANIC' && alarm.zone">
                                    - {{alarm.account.data.location.address |uppercase}}
                                </span>
                                    </p>
                                    <p class="pull-right">{{alarm.status | translate}} </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
                <ui-loading showloading="vm.updateInProgress&&!vm.query" type="loading-partial-view"></ui-loading>

            </div>


        </uib-tab>


    </uib-tabset>
</scrollable-tabset>


