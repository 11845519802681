<form name="noteTicket" novalidate>
    <div class="modal-cloud">
        <div class="modal-header">
            <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
            <h3 class="modal-title">{{'LOG_TICKET' | translate }}</h3>
        </div>
        <div class="table-in-modal">
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">

                        <table datatable="" dt-options="vm.dtOptions" dt-columns="vm.dtColumns" class="row-border hover"></table>





                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <ui-button action="cancel" text="{{'CLOSE' | translate }}" actionclick="vm.cancel()"></ui-button>
        </div>
    </div>
</form>
