<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'PAYMENT_METHODS'|translate}}</h3>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <br>
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="row">

                    <div class="col-xs-9" style="vertical-align: middle">
                        <strong>{{('REGISTERED_CARDS'|translate).toUpperCase()}}</strong>
                    </div>
                    <div class="col-xs-3">
                        <ui-button class="pull-right" action="save" text="{{'ADD_NEW_PAYMENT_METHOD'|translate}}"
                                   actionclick="vm.newPaymenMethod()"></ui-button>
                    </div>

                </div>

               </div>

            <div class="panel-body">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                        <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>
                        <no-data ng-if="!vm.init&&vm.methods.length===0" msg="{{'NO_PAYMENT_METHODS_AVALIABLE'|translate}}"></no-data>
                        <table ng-if="!vm.init&&vm.methods.length>0"  class="table dataTable table-striped table-bordered dt-responsive">
                            <thead>
                            <tr>
                                <th>{{'BILLINGS_CARD_TYPE'|translate}}</th>
                                <th>{{'CREDIT_CARD_NUMBER'|translate}}</th>
                                <th>{{'NAME_OWNER'|translate}}</th>
                                <th>{{'DUE_DATE'|translate}}</th>
                                <th>{{'ACTION'|translate}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ng-repeat="method in vm.methods" class="text-center">
                                <td>{{method.paymentMethod}}</td>
                                <td>{{method.maskedNumber}}</td>
                                <td>{{method.name}}</td>
                                <td>{{method.expirationDate.month}}/{{method.expirationDate.year}}</td>
                                <td>
                                    <ui-button uib-popover="{{'HELP_DELETE_PAYMENT_METHOD'| translate}}"
                                               popover-trigger="'mouseenter'" popover-placement="bottom" action="delete"
                                               mini="true" actionclick="vm.deleteMethod(method._id)"></ui-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">
        <div class="row newMethod">
            <ui-button action="cancel"
                       actionclick="vm.cancel()"></ui-button>
        </div>
    </div>
</div>
