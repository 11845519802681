<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'ALARMS_EVENTS_DETAILS'|translate}}</small></h3>
            </div>
        </div>
    </div>
    <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>

    <!--<ui-loading type="loading-partial-view" ng-if="vm.init"></ui-loading>-->
    <div class="modal-body"  ng-if="!vm.init">
        <div class="row info-cm">
            <div class="col-xs-2 col-lg-2 col-md-2 ">
                <!--<i class="{{vm.alarm.account.cloudIcon.image.svgPath}}"  ng-style="{'fill':vm.alarm.account.cloudIcon.color.value}"></i>-->
                <img class="img-responsive" ng-src="{{vm.alarm.centralStation.cloudImage}}">
            </div>
            <div class="col-xs-10">
                <h3>{{vm.alarm.centralStation.companyInfo.name}} - {{vm.alarm.centralStation.companyInfo.shortName}}</h3>
                <p>{{vm.alarm.centralStation.companyInfo.location.region.formattedAddress}} </p>
                <a>{{vm.alarm.centralStation.companyInfo.email}}<i class="icon icon-ico-atencion-llamadas-call-center"></i> {{vm.alarm.centralStation.companyInfo.phone}}</a>
            </div>
        </div>
        <div class="row element">
            <div class="col-lg-12 col-md-12 col-xs-12 alarms-name">
                <h2 ng-if="vm.alarm.stateRule.classification.key">
                    <i class="iconos {{vm.alarm.icon}}" ng-style="{'color':color}"></i>
                    {{vm.alarm.stateRule.classification.key? vm.alarm.stateRule.classification.key: "UNKNOWN" | translate}} {{ vm.alarm.stateRule.classification.key? '-': ' '}} {{vm.alarm.stateRule.classification.subClassification.key | translate}}  - {{vm.alarm.idCouch.split(':')[1]}}
                    <p class="pull-right time">{{hora}}</p>
                </h2>
                <h2 ng-if="!vm.alarm.stateRule.classification.key">
                    <i class="iconos icon icon-ico-ea-senal-desconocida" ng-style="{'color':color}"></i>
                    {{vm.alarm.stateRule.classification.key? vm.alarm.stateRule.classification.key: "UNKNOWN" | translate}} {{ vm.alarm.stateRule.classification.key? '-': ' '}} {{vm.alarm.stateRule.classification.subClassification.key | translate}}  - {{vm.alarm.idCouch.split(':')[1]}}
                    <p class="pull-right time">{{hora}}</p>
                </h2>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-xs-1 plan">
                    </div>
                    <div class="col-lg-11 col-md-11 col-xs-11">
                        <p ng-if="vm.alarm.stateRule.classification.key ==  'MOBILE_PANIC'">{{'BY' | translate}} {{vm.userAlarm}}</p>
                        <p ng-if="vm.alarm.stateRule.classification.key !=  'MOBILE_PANIC'">{{vm.zoneAlarm | uppercase}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row element">
            <div class="col-lg-1 col-md-1 col-xs-1 plan">
            </div>
            <div class="col-lg-11 col-md-11 col-xs-11 plan">
                <hr/>
                <p>{{"STATUS" | translate}}:</p>
                <p >{{vm.alarm.status | translate}} </p>
                <hr/>
            </div>
        </div>
        <div ng-if="vm.alarm.stateRule.classification.key ==  'MOBILE_PANIC'">
            <div class="row element" >
                <div class="col-lg-1 col-md-1 col-xs-1">
                    <!--<img class="img-responsive account-type"  ng-style="{'color':vm.alarm.account.cloudIcon.color.value}"  ng-src="{{icon}}">-->
                    <svg class="account-type">
                        <path ng-attr-d="{{vm.alarm.account.data.cloudIcon.image.svgPath}}" fill="{{vm.alarm.account.data.cloudIcon.color.value}}" transform="scale(0.1)"/>
                    </svg>
                    <!--<i class="{{vm.alarm.account.cloudIcon.image.svgPath}}"  ng-style="{'fill':vm.alarm.account.cloudIcon.color.value}"></i>-->
                    <!--{{vm.alarm.account.cloudIcon.image.value}}-->
                </div>
                <div class="col-lg-11 col-md-11 col-xs-11 app">
                    <p>{{'APP_ASSOCIATION_TO' | translate}} {{vm.alarm.account.data.name}}</p>
                    <br>
                </div>
            </div>
            <div class="row element">
                <div class="col-lg-1 col-md-1 col-xs-1 localizacion">
                    <i class="icon icon-ico-cloud-localizacion-en-mapa"></i>
                </div>
                <div id="direccion" class="col-lg-11 col-md-11 col-xs-11 ubication-panico">
                    <p>{{'PLACE_EVENT' | translate}}: </p>
                    <p class="alarm-ubication" ng-if="vm.direccionPanico">{{vm.direccionPanico}}</p>
                    <p class="alarm-ubication" ng-if="!vm.direccionPanico">{{ 'LOCATION_NOT_AVAILABLE' |translate}} </p>
                    <!--<p class="alarm-ubication" ng-if="direccionPanico==''">{{vm.alarm.account.location.address}} - {{vm.alarm.account.location.region}} </p>-->
                    <br>
                </div>
            </div>
            <div class="row element" >
                <div class="col-lg-12 col-md-12 col-xs-12" ng-if="vm.direccionPanico">
                    <!--<google-map latitude="{{latitude}}" longitude="{{longitude}}"></google-map>-->
                    <google-map  positions="vm.positions"></google-map>
                </div>

            </div>
        </div>
        <div class="row element" ng-if="vm.alarm.stateRule.classification.key !=  'MOBILE_PANIC'">
            <div class="col-lg-1 col-md-1 col-xs-1 localizacion">
                <svg class="account-type">
                    <path ng-attr-d="{{vm.alarm.account.data.cloudIcon.image.svgPath}}" fill="{{vm.alarm.account.data.cloudIcon.color.value}}" transform="scale(0.1)"/>
                </svg></div>
            <div class="col-lg-11 col-md-11 col-xs-11 app">
                <p class="alarm-ubication">{{vm.alarm.account.data.name}}  </p>

                <p class="alarm-ubication" >{{vm.alarm.account.data.location.address}} - {{vm.alarm.account.data.location.region}} - {{'ACCOUNT' | translate}} {{vm.alarm.account.data.number}}  </p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ui-button  class=" icon-ok" action="ok" actionclick="vm.cancel()" text="{{'OK'|translate}}"></ui-button>
    </div>
</div>
