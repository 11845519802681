<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'ACCOUNT_DETAILS'|translate}}</small></h3>
            </div>
        </div>
    </div>
    <ui-loading showloading="vm.init||vm.iconRdy||vm.colorRdy" type="loading-partial-view"></ui-loading>


    <!--<ui-loading type="loading-partial-view" ng-if="vm.init"></ui-loading>-->
    <div class="modal-body" ng-if="!(vm.init||vm.iconRdy||vm.colorRdy)">
        <div class="row info-cm">
            <div class="col-xs-2">
                <img class="img-responsive" ng-src="{{vm.centralStation.cloudImage}}">
            </div>

            <div class="col-xs-10">
                <h3> {{vm.centralStation.companyInfo.name}} - {{vm.centralStation.companyInfo.shortName}} </h3>
                <p>{{vm.centralStation.companyInfo.location.address}} </p>
                <a>{{vm.centralStation.companyInfo.email}}<i class="icon icon-ico-atencion-llamadas-call-center"></i>
                    {{vm.centralStation.companyInfo.phone}}</a>
            </div>
        </div>

        <div class="row element">
            <div class="col-lg-1 col-md-1 col-xs-1">
                <svg class="account-type-account">
                    <path ng-attr-d="{{vm.account.cloudIcon.image.svgPath}}" fill="{{vm.account.cloudIcon.color.value}}"
                          transform="scale(0.1)"/>
                </svg>
            </div>
            <div class="col-lg-11 alarms-name">
                <h2>{{vm.account.number}} - {{vm.account.name}}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-11 account-ubication">
                <p>{{vm.account.location.address}} /{{'ACCOUNT'|translate}}: {{vm.account.number}}</p>
                <div class="row">
                    <div class="col-lg-6 col-off-set-3 info-fact">
                        <ui-select ng-model="vm.config.icon" name="icon" id="icon" required
                                   ng-change="vm.refreshIcon()">
                            <ui-select-match placeholder="{{' SELECT_ICON'|translate}}">
                                <svg class="account-typeIcon">
                                    <path ng-attr-d="{{$select.selected.svgPath}}" transform="scale(0.1)"/>
                                </svg>
                                {{$select.selected.description}}
                            </ui-select-match>
                            <ui-select-choices
                                    repeat="icon in vm.icons | filter: {description: $select.search} track by icon._id">
                                <svg class="account-typeIcon">
                                    <path ng-attr-d="{{icon.svgPath}}" transform="scale(0.1)"/>
                                </svg>
                                <span ng-bind-html="icon.description | highlight: $select.search"></span>
                            </ui-select-choices>
                        </ui-select>
                        <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-off-set-3 info-fact">
                        <ui-select ng-model="vm.config.color" name="color" id="color" required
                                   ng-change="vm.refreshIcon()">
                            <ui-select-match
                                    placeholder="{{'SELECT_COLOR'|translate}}{{account.cloudIcon.color.value}}">
                                <canvas style="width: 20px; height: 20px; background-color:{{$select.selected.hexColor}};margin-right: 5px"></canvas>
                                {{$select.selected.description}}
                            </ui-select-match>
                            <ui-select-choices
                                    repeat="color in vm.colors | filter: {description: $select.search} track by color._id">
                                <canvas style="width: 20px; height: 20px; background-color:{{color.hexColor}}; margin-right: 5px"></canvas>
                                <span ng-bind-html="color.description | highlight: $select.search"></span>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                </div>
                <hr/>
            </div>


        </div>
    </div>
    <div class="modal-footer">
        <ui-button class="icon-ok" action="ok" actionclick="vm.update()" text="{{'UPDATE'|translate}}"></ui-button>
        <ui-button class="icon-ok" action="ok" actionclick="vm.cancel()" text="{{'CANCEL'|translate}}"></ui-button>
    </div>
</div>
