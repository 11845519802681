<!DOCTYPE html>

<html>
<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <base href="/">
  <title>NEVULA Cloud · Powered by XPECTRA</title>
  <meta name="description" content="">
  <meta name="viewport" content="width=device-width">
  <!-- Place favicon.ico and apple-touch-icon.png in the root directory -->
  <link rel="shortcut icon"   href="favicon.ico"/>
  <link rel="stylesheet" href="css/vendor.css">
  <link rel="stylesheet" href="css/app.css">
</head>
<body  ng-cloak ng-app="cloudxcmClientsApp" ng-controller="LayoutCtrl" class="hold-transition skin-blue sidebar-mini"  ng-class="{ 'login-page': isLoggedIn==false }" >
<div ng-if="!isLoggedIn">
  <div class="bg-login-animate"></div>
  <div class="loading-app" align="center">
    <img src="images/Logo%20Nevula.png" class="xpectra-header-loading"/>
    <br /><br /><br /><br />
    <br /><br /><br /><br />
    <br /><br /><br /><br />
    <br /><br /><br /><br />
    <svg id="main-loading" class="align-vertical" width="250" height="250" style="margin: 0 auto;">
      <circle class="colored black-loading" cx="100" cy="100" r="40"> </circle>
    </svg>
  </div>
</div>

<!--<div  ng-controller="LayoutCtrl" class="hold-transition skin-blue sidebar-mini"  ng-class="{ 'login-page': isLoggedIn==false }" >-->
<!--[if lt IE 7]>
<p class="browsehappy">You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
<![endif]-->

<!-- Add your site or application content here -->
<div id="main" ng-if="isLoggedIn"  class="wrapper">
  <div ng-include="'components/navbar/navbar.html'">
  </div>
  <div ng-include="'components/sidebar/sidebar.html'">
  </div>
  <div class="content-wrapper">

    <section class="content-header">
      <h1 ng-cloak=""><strong>{{ (titulo | translate)+' '+(!!subtitulo ? ' > ': '')  }}</strong>
        <small>{{  subtitulo | translate }}</small>
      </h1>
    </section>
    <section class="content">
      <!-- Your Page Content Here -->
      <div ng-view=""></div>
    </section><!-- /.content -->
  </div> <!-- /.content-wrapper-->

</div>



<div ng-if="isLoggedIn" ng-include="'components/footer/footer.html'"></div>
<div ng-if="isLoggedIn==false" class="animate-if">
  <!-- Your Page Content Here -->
  <div ng-view=""></div>
</div><!-- /.content-wrapper -->

<!-- Google Analytics: change UA-XXXXX-X to be your site's ID -->
<script>
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-XXXXX-X');
  ga('send', 'pageview');
</script>

<!--[if lt IE 9]>
<!--<script src="bower_components/es5-shim/es5-shim.js"></script>-->
<!--<script src="bower_components/json3/lib/json3.min.js"></script>-->
<!--[endif]-->

<script src='https://maps.googleapis.com/maps/api/js?sensor=false&libraries=drawing'></script>

<script src="js/vendor.js"></script>
<script src="js/app.js"></script>
<script src="js/templates.js"></script>

</body>
</html>
