<no-data ng-if="vm.centralStationsRdy&&vm.centralStations.length===0"
         msg="{{'NO_CENTRAL_STATIONS_AVALIABLE'|translate}}"></no-data>
<scrollable-tabset ng-if="!(vm.centralStationsRdy&&vm.centralStations.length===0)">
    <uib-tabset>
        <uib-tab ng-repeat="cs in vm.centralStations" index="$index"
                 select="vm.fetchTickets(cs)">
            <uib-tab-heading>
                <i class="icon icon-ico-cloud-alarmas-eventos"></i> {{ cs.companyInfo.name |translate}}

            </uib-tab-heading>


            <div class="row centroM">
                <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1 infocm">
                    <img class="imgCM" ng-src="{{cs.cloudImage}}">
                </div>
                <div class="col-xs-8 col-sm-8 col-md-10 col-lg-10 infcm-ubi">
                    <h3> {{cs.companyInfo.name}} - {{cs.companyInfo.shortName}} </h3>
                    <p>{{cs.companyInfo.location.address}}
                    </p>
                    <p><i class="icon icon-ico-cloud-email"></i> <a class="contact">{{cs.companyInfo.email}} <i
                            class="icon icon-ico-cloud-telefono"></i> {{cs.companyInfo.phone}}</a></p>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <div class="btn-tag pull-right">
                        <ui-button uib-popover="{{'HELP_REFRESH'| translate}}" popover-trigger="'mouseenter'"
                                   popover-placement="bottom" action="refresh" mini="true"
                                   actionclick="vm.refresh(vm.currentCs)"></ui-button>
                    </div>
                </div>


            </div>

            <br>

            <ui-loading showloading="vm.query" type="loading-partial-view"></ui-loading>
            <no-data ng-if="!vm.query&&vm.tickets.length===0" msg="{{'NO_TICKETS_AVALIABLE'|translate}}"></no-data>

            <div class="list-group" infinite-scroll="vm.fetchTickets(vm.currentCs)"
                 infinite-scroll-use-document-bottom="true" infinite-scroll-disabled="vm.noMoreDataAvaliable">

                <a ng-click="vm.openTicketModal(ticket.idCentralStation,ticket.idCouch)"
                   ng-repeat="ticket in vm.tickets" class="list-group-item">

                    <div class="supportInfo">

                        <div class="row nameAccount" ng-click="actions.OpenModal('tickets',ticket)">
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                            <div class="col-xs-8 col-sm-8 col-md-7 col-lg-7 row-title">
                                <div class="img-account-type">
                                    <svg class="account-type">
                                        <path ng-attr-d="{{ticket.account.cloudIcon.image.svgPath}}"
                                              fill="{{ticket.account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                                    </svg>
                                    <!--<img class="img-responsive account-type" ng-src="{{ticket.iconAccount}}">-->
                                </div>
                                <p>{{ticket.account.name}}</p>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3">
                                <div class="pull-right">{{'DATE_CREATE'| translate}}: {{ticket.createdAt}}</div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <ui-button action="log" supermini="true" overtext="{{'LOG_TICKET'|translate}}"
                                           ng-click="vm.log(ticket.idCentralStation,ticket.idCouch);$event.stopPropagation();"></ui-button>
                            </div>
                        </div>


                        <div class="row nameAccount">
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                            <div class="col-xs-8 col-sm-8 col-md-7 col-lg-8 row-title">
                                <i class="{{ticket.iconStatus}}"></i>
                                <span>{{ticket.code}} / {{ticket.requestName}}</span>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-3 col-lg-2">
                                <div class="pull-right">{{ticket.attentionDate}}</div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1" ng-if="ticket.status !== 'CLOSED'">
                                <ui-button action="note" supermini="true" overtext="{{'NEW_NOTE'|translate}}"
                                           ng-click="vm.newNote(ticket.idCouch,cs.idCentralStation);$event.stopPropagation();"></ui-button>
                            </div>
                        </div>


                    </div>

                </a>
                <ui-loading showloading="vm.updateInProgress&&!vm.query" type="loading-partial-view"></ui-loading>
            </div>


        </uib-tab>


    </uib-tabset>
</scrollable-tabset>