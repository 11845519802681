<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'ACC_DETAILS'|translate}}</small></h3>
            </div>
        </div>
    </div>


    <!--<ui-loading type="loading-partial-view" ng-if="init"></ui-loading> ng-if="!init"-->
    <div class="modal-body" ng-if="!init" >
        <!---->
        <div class="row info-cm">
            <div class="col-xs-2 col-lg-2 col-md-2 ">
                <!--<i class="{{alarma.account.cloudIcon.image.svgPath}}"  ng-style="{'fill':alarma.account.cloudIcon.color.value}"></i>-->
                <img class="img-responsive" ng-src="{{vm.centralStation.cloudImage}}">
            </div>
            <div class="col-xs-10">
                <h3>{{vm.centralStation.companyInfo.name}} - {{vm.centralStation.companyInfo.shortName}} </h3>
                <p>{{vm.centralStation.companyInfo.location.region.formattedAddress}} </p>
                <a>{{vm.centralStation.companyInfo.email}}<i class="icon icon-ico-atencion-llamadas-call-center"></i> {{vm.centralStation.companyInfo.phone}}</a>
            </div>
        </div>
        <div class="row element">
            <br>
            <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="bs-example" data-example-id="table-within-panel">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <span class="number-receipt"> <span class="text-right pull-right date">{{'DATE' | translate}}: {{today}}</span></span>
                            </p>
                        </div>
                        <div class="panel-body">
                            <p class="receipt-account"> <span class="title">{{'CLIENT' | translate}}: </span> {{vm.receipts[0].proforma.client.data.name}} |  <spam><span class="title">{{'TYPE_CLIENT'| translate}}:</span> {{vm.receipts.proforma.client.data.clientType}}</spam></p>
                            <span ><span class="title">{{'PHONE'| translate}}:</span> {{vm.receipts[0].proforma.account.additional.phone}} | <spam><span class="title">{{'EMAIL'| translate}}:</span> {{vm.receipts[0].proforma.client.data.email}}</spam> </span>
                            <p><span class="title">{{'ADDRESS'| translate}}:</span> {{vm.receipts[0].proforma.client.location.address}}</p>
                        </div>
                        <table class="table">
                            <thead>
                            <tr >
                                <!--<th>#</th>-->
                                <th>{{'RECEIPT' | translate}}</th>
                                <th>{{'ACCOUNT' |translate}}</th>
                                <th>{{'TOTAL' | translate}}</th>
                            </tr>
                            </thead>
                            <tbody ng-repeat="receipt in vm.receipts">
                            <tr ng-if="receipt.status ==='PENDING' || receipt.status ==='PAST_DUE'" >
                                <!--<th scope="row" >{{$index+1}}</th>-->
                                <td>{{'RECEIPT_NUM'|translate}}: {{receipt.proforma.number}}</td>
                                <td>{{receipt.proforma.account.data.number }} - {{receipt.proforma.account.data.name}}</td>
                                <td>{{receipt.proforma.total | currency}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <p class="subtotal text-right pull-right"><span class="title" >{{'TOTAL' | translate}}:  </span> {{vm.total | currency}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ui-button  class="icon-ok" action="cancel" actionclick="vm.cancel()" text="{{'CANCEL'|translate}}"></ui-button>
        <ui-button action="save" actionclick="vm.pay()" text="{{'BILLINGS_MAKE_PAYMENT'|translate}}"></ui-button>
    </div>
</div>
