<!--
<div class="pages" align="center">

        <img src="images/nevula-logo-md-inverse.png" class="xpectra-header-loading"/>




</div>
-->

<div class="pages" align="center">
  <img src="/images/Logo Nevula.png"  class="xpectra-header-loading"/>
  <h1> {{'USER_NOT_REGISTERED'|translate}} </h1>
  <button ng-click="vm.logOut();"><span>{{'BACK_TO_AZURE'|translate}}</span></button>
</div>
