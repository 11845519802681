<form name="formTicket" novalidate>
    <div class="modal-cloud">

        <div class="modal-header">
            <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
            <div class="row">
                <div class="col-xs-10">
                    <h3>{{'TICKET_DETAILS'|translate}}</small></h3>
                </div>
            </div>
        </div>
        <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>

        <div class="modal-body" ng-if="!vm.init">

            <div class="row info-cm">
                <div class="col-xs-2">
                    <img class="img-responsive" ng-src="{{vm.ticket.centralStation.cloudImage}}">
                </div>
                <div class="col-xs-10">
                    <h3> {{vm.ticket.centralStation.companyInfo.name}} -
                        {{vm.ticket.centralStation.companyInfo.shortName}} </h3>
                    <p>{{vm.ticket.centralStation.companyInfo.location.region.formattedAddress}} </p>
                    <i class="icon icon-ico-cloud-email"></i> {{vm.ticket.centralStation.companyInfo.email}} <i
                        class="icon icon-ico-cloud-telefono"> </i> {{vm.ticket.centralStation.companyInfo.phone}}
                </div>
            </div>

            <div class="row element">
                <div class="col-lg-1 alarms-name">
                    <i class="icon icon-ico-cloud-ticket"></i><br/>
                    <div class="alarms-name">
                        <i class="icon icon-ico-cloud-solicitud"></i>
                    </div>

                </div>
                <div class="col-lg-11">
                    <div class="col-lg-9 modal-info">
                        <div>{{'TICKET'|translate}}: {{vm.ticket.code}}</div>
                        <div ng-if="vm.ticket.status != 'ASSIGNED' && vm.ticket.status != 'REVOKED'">
                            {{'STATUS'|translate}}: {{vm.ticket.status|translate}}
                        </div>
                        <div>{{vm.requestCode}}</div>
                        <div>{{vm.requestIncidence}}</div>
                    </div>
                    <div class="col-lg-3" ng-if="vm.requestCloudImage">
                        <img class="zoom" width="100" height="80" ng-src="{{vm.requestCloudImage}}">
                    </div>
                </div>
            </div>

            <div class="row" ng-if="vm.ticket.status != 'ASSIGNED' && vm.ticket.status != 'REVOKED'">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>

            <div class="row lista" ng-if="vm.ticket.status != 'ASSIGNED' && vm.ticket.status != 'REVOKED'">
                <div class="col-lg-1 alarms-name"></div>
                <div class="col-lg-11">
                    <div class="col-lg-9">
                        <div>{{'ASSIGNED_TECHNICIAN'|translate}}</div>
                        <div>{{vm.ticket.technician.name}}</div>
                        <div><i class="icon icon-ico-cloud-telefono"> </i> {{vm.phoneTechnician}}</div>
                        <div><i class="icon icon-ico-cloud-email"> </i> {{vm.ticket.technician.email}}</div>
                    </div>
                    <div class="col-lg-3" ng-if="vm.ticket.technician.cloudImage">
                        <img class="zoom" width="100" height="80" ng-src="{{vm.ticket.technician.cloudImage}}">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>

            <div class="row element" ng-show="dateTicket || hourTicket">
                <div class="col-lg-1 alarms-name">
                    <i class="{{iconStatus}}"></i>
                </div>
                <div class="col-lg-11">
                    <div class="col-lg-8">
                        <div>{{dateTicket}}</div>
                        <div>{{hourTicket}}</div>

                    </div>
                </div>
            </div>

            <div class="row" ng-show="dateTicket || hourTicket">
                <div class="col-lg-1 alarms-name">
                </div>
                <div class="col-lg-11">
                    <hr/>
                </div>
            </div>


            <div class="row element">
                <div class="col-lg-1 alarms-name">
                    <svg class="icon svg-account-type" ng-if="!isEditable">
                        <path ng-attr-d="{{vm.ticket.account.cloudIcon.image.svgPath}}"
                              fill="{{vm.ticket.account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                    </svg>
                </div>
                <div class="col-lg-11">
                    <div class="col-lg-8">
                        <div>{{vm.ticket.account.name}}</div>
                        <div>{{vm.accountAddress}}</div>
                        <div>{{'ACCOUNT'|translate}} {{vm.ticket.account.number}}</div>
                    </div>
                </div>

            </div>

        </div>

        <div class="modal-footer">

            <ui-button action="ok" actionclick="vm.cancel()"
                       text="{{'OK'|translate}}"
                       ></ui-button>
        </div>

    </div>
</form>
