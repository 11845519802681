<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'ACCOUNT_DETAILS'|translate}}</small></h3>
            </div>
        </div>
    </div>
    <ui-loading showloading="vm.init" type="loading-partial-view"></ui-loading>


    <!--<ui-loading type="loading-partial-view" ng-if="vm.init"></ui-loading>-->
    <div class="modal-body"  ng-if="!vm.init">
        <div class="row info-cm">
            <div class="col-xs-2">
                <img class="img-responsive" ng-src="{{vm.centralStation.cloudImage}}">
            </div>

            <div class="col-xs-10">
                <h3> {{vm.centralStation.companyInfo.name}} - {{vm.centralStation.companyInfo.shortName}} </h3>
                <p>{{vm.centralStation.companyInfo.location.address}} </p>
                <a>{{vm.centralStation.companyInfo.email}}<i class="icon icon-ico-atencion-llamadas-call-center"></i> {{vm.centralStation.companyInfo.phone}}</a>
            </div>
        </div>

        <div class="row element">
            <div class="col-lg-1 col-md-1 col-xs-1">
                <svg class="account-type-account">
                    <path ng-attr-d="{{vm.account.cloudIcon.image.svgPath}}" fill="{{vm.account.cloudIcon.color.value}}" transform="scale(0.1)"/>
                </svg>
            </div>
            <div class="col-lg-11 alarms-name">
                <h2>{{vm.account.number}} - {{vm.account.name}}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-11 account-ubication">
                <p>{{vm.account.location.address}}   /{{'ACCOUNT'|translate}}: {{vm.account.number}}</p>
                <div class="row">
                    <div class="col-lg-12 info-fact">
                        <p> {{'ACCOUNT_STATE'|translate}}: <span class="text-right pull-right">{{vm.account.status | translate}}</span></p>
                        <p>{{'SERVICE_PLAN'|translate}}: <span class="text-right pull-right">{{vm.account.serviceType | translate}}</span></p>
                        <p>{{'REPORTS_INVOICING'|translate}}: <span class="text-right pull-right">{{vm.account.debt|currency}}$</span></p>
                        <p>{{'ENABLE_NOTIFICATION'|translate}} </span> <span class="text-right pull-right">Habilitado</span></p>
                    </div>
                </div>
                <hr/>
            </div>
        </div>
        <div class="row" ng-if="vm.account.lastStatus">
            <div ng-if="vm.account.lastStatus.status == 'OPEN'">
                <div  class="col-lg-1 col-md-1 col-xs-1 img-account-type">
                    <img class="img-responsive account-type" style="margin-left: 10px; max-width: 120%" ng-src="http://nevulametacentro.blob.core.windows.net/metacenter/A_Disarmed.png">
                </div>
                <div class="col-md-11 statusPanel" ng-if="vm.account.lastStatus" >
                    <p class="">{{'STATUS_PANEL'|translate}}</p>
                    <div ng-if="vm.account.lastStatus.status == 'OPEN'">
                        <p class="statusPanel" ng-if="!vm.account.lastStatus.accountUser">Desarmado por: Usuario Desconocido  <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span></p>
                        <p class="statusPanel" ng-if="vm.account.lastStatus.accountUser">Desarmado por: {{vm.account.lastStatus.accountUser.name}} <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span> </p>
                        <hr/>
                    </div>
                    <div ng-if="vm.account.lastStatus.status == 'CLOSE'">
                          <p class="statusPanel" ng-if="!vm.account.lastStatus.accountUser">Armado por: Usuario Desconocido <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span></p>
                        <p class="statusPanel" ng-if="vm.account.lastStatus.accountUser">Armado por: {{vm.account.lastStatus.accountUser.name}} <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span> </p>
                        <hr/>
                    </div>
                </div>
            </div>
            <div ng-if="vm.account.lastStatus.status == 'CLOSE'">
                <div  class="col-lg-1 col-md-1 col-xs-1 img-account-type">
                    <img class="img-responsive account-type"  style="margin-left: 10px; max-width: 120%" ng-src="http://nevulametacentro.blob.core.windows.net/metacenter/A_Armed_Stay.png">
                </div>
                <div class="col-lg-11 col-md-11 col-xs-1 inf-name">
                    <p class="statusTitle">{{'STATUS_PANEL'|translate}}</p>
                    <p class="statusPanel" ng-if="!vm.account.lastStatus.accountUser">Armado por: Usuario Desconocido <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span></p>
                    <p class="statusPanel" ng-if="vm.account.lastStatus.accountUser">Armado por: {{vm.account.lastStatus.accountUser.name}} <span class="text-right pull-right">{{vm.account.lastStatus.date}}</span></p>
                    <hr/>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12" ng-if="vm.positions">
                <!--<google-map latitude="{{latitude}}" longitude="{{longitude}}"></google-map>-->
                <google-map positions="vm.positions"></google-map>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ui-button  class="icon-ok" action="ok" actionclick="vm.cancel()" text="{{'OK'|translate}}"></ui-button>
    </div>
</div>
