<div ng-include="'components/navbar/navbar.html'"></div>

<div class="container">
  <p>The delete user and user index api routes are restricted to users with the 'admin' role.</p>
  <ul class="list-group">
    <li class="list-group-item" ng-repeat="user in users">
        <strong>{{user.name}}</strong><br>
        <span class="text-muted">{{user.email}}</span>
        <a ng-click="delete(user)" class="trash"><span class="glyphicon glyphicon-trash pull-right"></span></a>
    </li>
  </ul>
</div>