<div class="bg-login-animate"></div>
<div class="loading-app" align="center">
  <img src="images/Logo Nevula.png"  class="xpectra-header-loading"/>
  <br /><br /><br /><br />
  <br /><br /><br /><br />
  <br /><br /><br /><br />
  <br /><br /><br /><br />
  <svg id="main-loading" class="align-vertical" width="250" height="250" style="margin: 0 auto;">
    <circle class="colored black-loading" cx="100" cy="100" r="40"> </circle>
  </svg>
</div>
