<uib-tabset>
    <uib-tab>
        <uib-tab-heading>
            <i class="icon icon-ico-cloud-solicitud"></i>  {{'REQUESTS'|translate}}
        </uib-tab-heading>
        <requests/>
    </uib-tab>
    <uib-tab>
        <uib-tab-heading>
            <i class="icon icon-ico-cloud-solicitud"></i>  {{'TICKETS'|translate}}
        </uib-tab-heading>
        <tickets/>
    </uib-tab>
</uib-tabset>