
<div ng-if="widgetType=='measure'">
  <!--<ui-loading type="loading-partial-view" showloading="showloading"></ui-loading>-->
<div class="{{indicator.config.indicatorCssClass}} widgetEscalarItem" ng-repeat="indicator in widgetscontent.indicators">
  <div class="{{indicator.config.indicatorCssColor}}" ng-style="{'height': rowHeight*1.85}">
    <div class="inner">
      <p class="title">{{indicator.config.dataConfig.name}}</p>
      <h3  ng-if="indicator.config.dataConfig.value">{{indicator.config.dataConfig.value}}</h3>
    </div>
    <div class="icon">
      <i class="{{indicator.config.indicatorImgBg}}"></i>
    </div>
    <span class="small-box-footer">({{indicator.config.dataConfig.dateRange | translate}})</span>
  </div>
</div>
</div>

<div ng-if="widgetType=='chart'">
<div class="box box-primary containermenu overFade">
  <div class="box-header">
    <h3 class="box-title">{{title}}</h3>
    <div class="box-tools pull-right">

      <!--<div class="btn-group overFadeItem">
        <ui-button action="conf" actionclick="openModal('lg')" mini="true"></ui-button>
      </div>-->

      <div class="btn-group">
        <button class="btn btn-default dropdown-toggle overFadeItem" data-toggle="dropdown" title="Rango/Intervalo de Tiempo">
          <i class="fa fa-calendar"></i> {{starDateMenu}} - {{endDateMenu}}
        </button>
        <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
          <li class="dropdown-header">Rango de Fechas</li>
          <li class="divider"></li>
          <li ng-repeat="dateRanges in dateRanges" ng-click="selectDateRange(dateRanges.id)">
            <a href='#' ng-if="dateRanges.id!=indicator.config.dateRange && dateRanges.show" >{{dateRanges.range}}</a>
            <a href='#' ng-if="dateRanges.id===indicator.config.dateRange && dateRanges.show" style="color: #1e282c; background: #e1e3e9;">{{dateRanges.range}}</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
  <div class="box-body">
    <!--<ui-loading type="loading-partial-view" showloading="showloading"></ui-loading>-->
    <div class="alert" style="float:left;margin: 1em 2%;position: relative;" ng-if="noData">No existen datos en el intervalo seleccionado...</div>
    <highchart  chartype="bar" config="chartConfig"></highchart>
  </div>
</div>
</div>

<div class="{{indicatorCssClass}} widgetEscalarItem"  ng-if="widgetType=='minichart'">
  <ui-loading type="loading-partial-view" showloading="showloading"></ui-loading>
<div class="box box-primary {{widgetType}}"  ng-style="{'height': rowHeight*1.9}">
  <div class="box-header">
    <h3 class="box-title">{{title}}<br /><span>$292.131</span></h3>
    <div class="box-tools pull-right">
    </div>


  </div>
  <div class="box-body">
    <highchart chartype="bar" config="chartConfig" ></highchart>
  </div>
</div>
</div>

<div ng-if="widgetType=='statistic'">
  <ui-loading type="loading-partial-view" showloading="showloading"></ui-loading>
  <div class="box box-primary containermenu overFade">
    <div class="box-header">
      <h3 class="box-title">{{title}}</h3>
    </div>
    <div class="row indicator-filters-center">
      <div class="col-lg-12">
        <div class="box-tools">

          <div class="dropdown pull-left">
            <span>Regiones: </span>
            <a class="btn btn-default" href="#" role="button" data-toggle="dropdown">Todas <span class="caret"></span></a>
            <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
              <li ng-repeat="region in regions"><a href="#">{{region.name}}</a></li>
            </ul>
          </div>

          <div class="dropdown pull-left">
            <span>Tipo de Cliente: </span>
            <a class="btn btn-default" href="#" role="button" data-toggle="dropdown">Todos  <span class="caret"></span></a>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <li ng-repeat="clientType in clientTypes"><a href="#">{{clientType.name}}</a> </li>
            </ul>
          </div>

          <div class="dropdown pull-left">
            <span>Tipo de Evento: </span>
            <a class="btn btn-default" href="#" role="button" data-toggle="dropdown">Todos  <span class="caret"></span></a>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <li ng-repeat="eventType in eventTypes"><a href="#">{{eventType.name}}</a> </li>
            </ul>
          </div>

          <div class="dropdown pull-left">
            <span>Categoría: </span>
            <a class="btn btn-default" href="#" role="button" data-toggle="dropdown">Todas  <span class="caret"></span></a>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <li ng-repeat="category in categories"><a href="#">{{category.name}}</a> </li>
            </ul>
          </div>

          <div class="dropdown pull-left">
            <span>Período: </span>
            <a class="btn btn-default" href="#" role="button" data-toggle="dropdown">{{ranges[0].name}}  <span class="caret"></span></a>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <li ng-repeat="range in ranges"><a href="#">{{range.name}}</a> </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <div class="alert" style="float:left;margin: 1em 2%;position: relative;" ng-if="noData">No existen datos en el intervalo seleccionado...</div>
          <div style="width: 100%;"></div>
          <highchart  chartype="bar" config="chartConfig"></highchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="accumulator-totals">
            <h4>Acumulador</h4>
            <div class="accumulator-totals-item">
              <span>Últimas 8 Horas: </span> 10
            </div>
            <div class="accumulator-totals-item">
              <span>Últimas 16 Horas: </span> 80
            </div>
            <div class="accumulator-totals-item">
              <span>Últimas 24 Horas: </span> 150
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div ng-if="widgetType=='pie'">
  <div class="box box-primary containermenu overFade">
    <div class="box-header">
      <h3 class="box-title">{{title}}</h3>
      <div class="box-tools pull-right">

        <div class="btn-group overFadeItem">
          <ui-button action="conf" actionclick="openModal('lg')" mini="true"></ui-button>
        </div>

        <div class="btn-group">
          <button class="btn btn-default dropdown-toggle overFadeItem" data-toggle="dropdown" title="Rango/Intervalo de Tiempo">
            <i class="fa fa-calendar"></i> {{starDateMenu}} - {{endDateMenu}}
          </button>
          <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
            <li class="dropdown-header">Rango de Fechas</li>
            <li class="divider"></li>
            <li ng-repeat="dateRanges in dateRanges" ng-click="selectDateRange(dateRanges.id)">
              <a href='#' ng-if="dateRanges.id!=indicator.config.dateRange && dateRanges.show" >{{dateRanges.range}}</a>
              <a href='#' ng-if="dateRanges.id===indicator.config.dateRange && dateRanges.show" style="color: #1e282c; background: #e1e3e9;">{{dateRanges.range}}</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="box-body">
      <!--<ui-loading type="loading-partial-view" showloading="showloading"></ui-loading>-->
      <div class="alert" style="float:left;margin: 1em 2%;position: relative;" ng-if="noData">No existen datos en el intervalo seleccionado...</div>
      <highchart  chartype="bar" config="chartConfig"></highchart>
    </div>
  </div>
</div>



