<!--<div class="box box-primary">
  <div class="box-body">
    &lt;!&ndash;<button uib-popover="I appeared on mouse enter!" popover-trigger="'mouseenter'" popover-placement="top" uib-popover-popup-delay="250" uib-popover-append-to-body="true" class="btn btn-default">Mouseenter</button>&ndash;&gt;
    <h1 class="text-center">{{'WELCOME' | translate}} </h1>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>&lt;!&ndash; /.box-body &ndash;&gt;

</div>&lt;!&ndash; /.box &ndash;&gt;-->
