
<div class="modal-header">
  <ui-button action="cancel" actionclick="close()" supermini="true" class="pull-right"></ui-button>
  <h3 class="modal-title"> {{widgetscontent.title}} ({{'SETTINGS' | translate}})</h3>
</div>

<div class="modal-body widget-settings">
  <ui-loading ng-if="!done" type="loading-partial-view"></ui-loading>
  <div  ng-if="done" class="row">
    <div class="col-md-12">
      <tabset>
        <tab>
          <tab-heading>
            {{"GENERAL_SETTINGS" | translate}}
          </tab-heading>

          <form>
            <fieldset>
              <legend>{{"GENERAL_SETTINGS" | translate}}</legend>

              <div class="row">
                <div class="col-xs-12">
                  <div class="form-group">
                    <label>{{"WIDGET_NAME" | translate}}</label>
                    <input value="{{widgetscontent.title}}" class="form-control" disabled>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-xs-12">
                      <label>{{"DATE_RANGE" | translate}} </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label>{{"FROM" | translate}} </label>
                        <ui-button action="help" overtext=""></ui-button>
                        <div class="input-group">
                          <input type="text" class="form-control" datepicker-popup="{{format}}"
                                 ng-model="widgetscontent.dateRange[0]" is-open="date1.opened" datepicker-options="date1.dateOptions"
                                 close-text="{{'CLOSE' | translate}}"
                                 show-weeks="false" format-month="mmmm" year-range="2" show-button-bar="false" disabled />
                          <span class="input-group-btn"><ui-button action="pick-date" actionclick="openCalendar($event,date1)" overtext="{{'SELECT_DATE' | translate}}" mini="true"></ui-button> </span>
                        </div>
                        <p class="help-block"></p>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label>{{"TO" | translate}} </label>
                        <ui-button action="help" overtext=""></ui-button>
                        <div class="input-group">
                          <input type="text" class="form-control" datepicker-popup="{{format}}"
                                 ng-model="widgetscontent.dateRange[1]" is-open="date2.opened" datepicker-options="date2.dateOptions"
                                 close-text="{{'CLOSE' | translate}}"
                                 show-weeks="false" format-month="mmmm" year-range="2" show-button-bar="false" disabled />
                          <span class="input-group-btn">
                            <ui-button action="pick-date" actionclick="openCalendar($event,date2)" overtext="{{'SELECT_DATE' | translate}}" mini="true"></ui-button>
                          </span>
                        </div>
                        <p class="help-block"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>


        </tab>
        <tab>
          <tab-heading>
            {{"INDICATORS_SETTINGS" | translate}}
          </tab-heading>
          <div class="vertical-tabs-container">

          <tabset class="tabs-left">
            <tab ng-repeat="indicator in widgetscontent.indicators">
              <tab-heading>
                <i class="fa fa-bar-chart"></i> {{indicator.config.name}}
              </tab-heading>

              <div class="row">
                <div class="col-xs-12">
                  <form>
                    <fieldset>
                      <legend>{{"INDICATOR" | translate}} {{indicator.config.name}}</legend>

                      <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="form-group">
                            <label>{{"INDICATOR_NAME" | translate}} </label>
                            <ui-button action="help" overtext=""></ui-button>
                            <input type="text" value="{{indicator.config.name}}" class="form-control" disabled>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>{{"CHART_TYPE" | translate}} </label>
                            <ui-button action="help" overtext=""></ui-button>
                            <select class="form-control" name="chartType" ng-model="indicator.newconfig.type">
                              <option ng-repeat="chartType in indicator.defaultconf.value.chartTypes" ng-selected="chartType.id==indicator.newconfig.type" value="{{chartType.id}}">{{chartType.name}}</option>
                            </select>
                            <p class="help-block"></p>
                          </div>
                        </div>
                      </div>

                      <div class="box box-primary overFade">
                        <div class="box-header">
                          <h3 class="box-title">Filtros del Indicador:</h3>
                        </div>
                        <div class="box-body">

                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                <label>{{"SELECT_FILTER" | translate}}</label>
                                <ui-button action="help" overtext="{{'FILTER_BY' | translate}}: {{filter.name | translate}}"></ui-button>

                                <div class="dropdown dropdown-select-like">
                                  <a class="form-control" href="#" role="button" data-toggle="dropdown">
                                    <div ng-if="indicator.filterSettingMenu.name=='ALL'">{{"NONE" | translate}}<span class="caret"></span></div>
                                    <div ng-if="indicator.filterSettingMenu.name!='ALL'">{{indicator.filterSettingMenu.name | translate}}<span class="caret"></span></div>

                                  </a>
                                  <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
                                    <li ng-repeat="filterItem in indicator.defaultconf.value.availableFilters"
                                        ng-click="indicator.filterSettingMenu=filterItem;indicator.newconfig.id=filterItem.id;
                                        indicator.newconfig.name=filterItem.name;indicator.newconfig.name='filterValue'">
                                      <a href="#" ng-if="filterItem.name=='ALL'">{{"NONE" | translate}}</a>
                                      <a href="#" ng-if="filterItem.name!='ALL'">{{filterItem.name | translate}}</a>
                                    </li>
                                  </ul>
                                </div>
                                <p class="help-block"></p>
                              </div>
                            </div>
                          </div>

                          <div class="row"  ng-if="filterType(indicator.filterSettingMenu.id)!='byCountry' && filterType(indicator.filterSettingMenu.id)!='byAll'">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                <label>{{indicator.filterSettingMenu.name | translate}}</label>
                                <ui-button action="help" overtext="{{'Filtrar por '+indicator.filterSettingMenu.id}}"></ui-button>
                                <select class="form-control" ng-model="indicator.newconfig.filterValue">
                                  <option ng-repeat="filterValues in indicator.filterSettingMenu.values">{{filterValues.name}}</option>
                                </select>
                                <p class="help-block"></p>
                              </div>
                            </div>
                          </div>
                          <div ng-if="filterType(indicator.filterSettingMenu.id)=='byCountry' && filterType(indicator.filterSettingMenu.id)!='byAll'">
                            <div class="row">
                              <div class="col-xs-12">
                                <label>Filtrar por Ubicación:</label>
                              </div>
                            </div>
                            <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div class="form-group">
                                <label>País *</label>
                                <ui-button action="help" overtext="Filtrar por País"></ui-button>
                                <select class="form-control" name="country" ng-model="formAccount.location.address.country">
                                  <option ng-repeat="country in countries">{{country.name}}</option>
                                </select>
                                <p class="help-block"></p>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="state">{{'STATE'|translate}} *</label>
                                <ui-button action="help" overtext="HELP TEXT"></ui-button>
                                <select class="form-control" id="state" name="state" ng-model="indicator.newconfig.filterby">
                                  <option ng-repeat="filterValues in indicator.filterSettingMenu.values">{{filterValues.name}}</option>
                                </select>
                                <p class="help-block"></p>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div class="form-group" show-errors>
                                <label for="county">{{'MUNICIPALITY'|translate}} *</label>
                                <ui-button action="help" overtext="HELP TEXT"></ui-button>
                                <select class="form-control" id="county" name="county" ng-model="formAccount.location.address.country" ng-change="countryInsert()">
                                  <option ng-repeat="country in countries">{{country.name}}</option>
                                </select>
                                <p class="help-block"></p>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row" ng-if="filterType(indicator.filterSettingMenu.id)=='byAll'">
                            <div class="col-xs-12">
                              Seleccione un tipo de filtro
                            </div>
                          </div>

                        </div>
                      </div>
                      <!--

                      <div style="padding: 20px;background: #CC0000;color: white;">
                        <p>
                          indicator.newconfig: {{indicator.newconfig}}
                        </p>
                        <p>
                          indicator.filterSettingMenu: {{indicator.filterSettingMenu}}
                        </p>
                      </div>-->


                    </fieldset>
                  </form>
                </div>
              </div>

            </tab>
          </tabset>
          </div>

        </tab>
      </tabset>
    </div>
  </div>
</div>
<div class="modal-footer">
  <ui-button action="cancel" actionclick="cancel()"></ui-button>
  <ui-button action="save" actionclick="save()">{{'SAVE' |translate}}</ui-button>
</div>
