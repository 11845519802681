<div class="modal-cloud">
    <div class="modal-header">
        <ui-button action="cancel" actionclick="vm.cancel()" supermini="true" class="pull-right"></ui-button>
        <div class="row">
            <div class="col-xs-10">
                <h3>{{'BILLINGS_DETAILS'|translate}}</small></h3>
            </div>
        </div>
    </div>


    <div class="modal-body">
        <!---->
        <div class="row info-cm">
            <div class="col-xs-2 col-lg-2 col-md-2 ">
                <!--<i class="{{alarma.account.cloudIcon.image.svgPath}}"  ng-style="{'fill':alarma.account.cloudIcon.color.value}"></i>-->
                <img class="img-responsive" ng-src="{{vm.centralStation.cloudImage}}">
            </div>
            <div class="col-xs-10">

                <h3>{{vm.centralStation.companyInfo.name}} - {{vm.centralStation.companyInfo.shortName}}</h3>
                <p>{{vm.centralStation.companyInfo.location.region.formattedAddress}} </p>
                <a>{{vm.centralStation.companyInfo.email}}<i class="icon icon-ico-atencion-llamadas-call-center"></i> {{vm.centralStation.companyInfo.phone}}</a>
            </div>
        </div>

        <div class="row element">
            <br>
            <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="bs-example" data-example-id="table-within-panel">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <p class="title">{{'RECEIPT_NUM'| translate}}:
                                <span class="number-receipt">{{vm.receipt.proforma.number}} ({{vm.receipt.status | translate}})<span
                                        class="text-right pull-right">{{vm.receipt.createdAt}}</span></span>
                            </p>
                        </div>
                        <div class="panel-body">
                            <p class="receipt-account"><span class="title">{{'ACCOUNT' | translate}}: </span> <span
                                    class="number-receipt">{{vm.receipt.proforma.account.data.number}} - {{vm.receipt.proforma.account.data.name}}</span>
                            </p>
                            <p class="receipt-account"><span class="title">{{'CLIENT' | translate}}: </span>
                                {{vm.receipt.proforma.client.data.name}} |
                                <spam><span class="title">{{'ID_TAX'| translate}}:</span>
                                    {{vm.receipt.proforma.account.additional.fiscalId}}
                                </spam>
                            </p>
                            <span><span class="title">{{'PHONE'| translate}}:</span> {{vm.receipt.proforma.account.additional.phone}} | <spam><span
                                    class="title">{{'EMAIL'| translate}}:</span> {{vm.receipt.proforma.client.data.email}}</spam> </span>
                            <p><span class="title">{{'ADDRESS'| translate}}:</span>
                                {{vm.receipt.proforma.account.additional.formattedAddress}}</p>
                        </div>
                        <table class="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{'DESCRIPTION' | translate}}</th>
                                <th>{{'AMOUNT' |translate}}</th>
                                <th>{{'TOTAL' | translate}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ng-repeat="receipt in vm.receipt.proforma.concepts">
                                <th scope="row">{{$index+1}}</th>
                                <td>{{receipt.additional.description}}</td>
                                <td>{{receipt.data.consume}}</td>
                                <td>{{receipt.data.price|currency}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <span class="subtotal text-right pull-right"><span
                                class="title">{{'SUBTOTAL' | translate}}:  </span> {{vm.subtotalConsume|currency}}</span>
                        <table class="table" ng-if="vm.subtotalTax>0">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{'CODE' | translate}}</th>
                                <th>{{'TAX' |translate}}</th>
                                <th>{{'PERCENT' |translate}}</th>
                                <th>{{'TOTAL' |translate}}</th>
                            </tr>
                            </thead>
                            <tbody ng-repeat="tax in vm.receipt.proforma.concepts">
                            <tr ng-repeat="taxes in tax.additional.taxes">
                                <!--<div ng-repeat="taxes in tax.taxes">-->
                                <th scope="row">{{$parent.$index+1}}</th>
                                <td>{{taxes.code}}</td>
                                <td>{{taxes.name}}</td>
                                <td>{{taxes.rate*100}} %</td>
                                <td>{{taxes.cost|currency}}</td>
                                <!--</div>-->
                            </tr>
                            </tbody>
                        </table>
                        <p ng-if="vm.subtotalTax>0" class="subtotal text-right pull-right"><span class="title">{{'SUBTOTAL' | translate}}:  </span>
                            {{vm.subtotalTax|currency}}</p>
                    </div>
                    <p class="total text-right pull-right"><span class="title">{{'TOTAL' | translate}}:  </span>
                        {{(vm.subtotalTax+vm.subtotalConsume)|currency}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ui-button class="icon-ok" action="cancel" actionclick="vm.cancel()" text="{{'CANCEL'|translate}}"></ui-button>
        <ui-button action="save" actionclick="vm.pay()" ng-if="vm.receipt.status === 'PENDING' || vm.receipt.status === 'PAST_DUE'" class="" text="{{'BILLINGS_PAY'|translate}}"></ui-button>
    </div>
</div>
