
    <header id="nav" ng-controller="NavbarCtrl" ng-class="{'hr-default': typeof (appClient === 'undefined'),'hr-technician': appClient === 'technician','hr-client': appClient === 'client'}" class="main-header" style="">
      <!-- Logo -->
      <a style="" href="/" class="logo">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini"><img src="/images/nebula-minilogo.svg" class="img-responsive xpectra-logo"></span>
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg"><img src="/images/Logo Nevula - ST.png" class="img-responsive xpectra-logo"> </span>
      </a>

      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" role="navigation">
        <!-- Sidebar toggle button-->
        <!--<a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">-->
        <!--<span class="sr-only">Toggle navigation</span>-->

        <!-- AQUI ESTA LO DEL NAV EL LOGO MINI-->

        <!--</a><a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">-->
        <!--<span class="sr-only">HOLA MUNDO</span>-->
        <!--</a>-->

        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu">
          <div class="row icon-Header dropdown-container">


            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <span uib-popover="{{'HELP_TRANSLATE'|translate}}"  popover-trigger="'mouseenter'" popover-placement="left">
                 <i class="icon icon-ico-cloud-idioma" style="margin-right: 10px;  color: #005C9C  !important"></i>
              <ul class="dropdown-menu ">
              <li><a href="#" ng-click="changeLanguage('es')" >{{'BTN_TEXT_ES' | translate}} </a></li>
              <li><a href="#" ng-click="changeLanguage('en')" >{{'BTN_TEXT_EN' | translate}} </a></li>
              </ul>
              </span>
            </a>




            <span uib-popover="{{'LOGOUT'|translate}}" popover-trigger="'mouseenter'" popover-placement="left">
                <i class="icon icon-ico-cloud-salir salir" ng-click="logout()"></i>
            </span>
          </div>
          <div class="row notify">

            <!--
            <span uib-popover="{{'NOTIFICATION'| translate}}"  popover-trigger="'mouseenter'" popover-placement="bottom">
               <a href="#" class="notificacioness" style="color: #005C9C  !important" data-toggle="dropdown">
                 <i class="icon icon-ico-cloud-notificaciones" ></i>
               </a>
            </span>
            -->

            <div class="btn-group">
              <button type="button" class="btn btn-secondary dropdown-toggle Usuario " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="/images/icon-user-default.png" class="user-image " alt="User Image">
                <span ng-if="getCurrentUser.displayName" class="hidden-xs">{{getCurrentUser.displayName}}</span>
                <span ng-if="!getCurrentUser.displayName" class="hidden-xs">{{getCurrentUser.signInNames[0].value}}</span>
              </button>
              <div class="dropdown-menu">
                <!--width: 195px;-->
                <!--<a class="dropdown-item" href="#">{{'TECHSUPPORT'|translate}}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">{{'SUPERVISOR'|translate}}</a>
                <hr class="hr-menu-min">-->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" ng-click="changePassword()" href="#">{{'CHANGE_PASSWORD'|translate}} </a>
                <hr class="hr-menu-min">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" ng-click="logout()">{{'CLOSE_APP'|translate}} </a>
                <div class="dropdown-divider"></div>

              </div>

              <!--<img src="/images/icon-user-default.png" class="user-image " alt="User Image">-->
              <!--<span ng-if="getCurrentUser.firstName" class="hidden-xs">{{getCurrentUser.firstName}}</span>-->
              <!--<span ng-if="!getCurrentUser.firstName" class="hidden-xs">{{getCurrentUser.email}}</span>-->
              <!--</div>-->
            </div>
            <!-- User Account: style can be found in dropdown.less -->
          </div>
        </div>
      </nav>
    </header>


